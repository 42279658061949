html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.blockquote a {
  color: inherit;
}

.blockquote_style_1 .blockquote__text {
  letter-spacing: .03em;
  padding-top: 78px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 35px;
  line-height: 138%;
  position: relative;
}

.blockquote_style_1 .blockquote__text:before {
  content: "“";
  font-size: 123px;
  line-height: 56px;
  display: block;
  position: absolute;
  top: 30px;
  left: 0;
}

@media (min-width: 1100px) {
  .blockquote_style_1 .blockquote__text {
    padding: 34px 20px 32px 125px;
    line-height: 52px;
  }

  .blockquote_style_1 .blockquote__text:before {
    top: 60px;
  }
}

.blockquote_style_2 {
  background: #f6ead4;
  border-radius: 32px;
  margin-bottom: 32px;
  padding: 20px 20px 28px;
}

.blockquote_style_2 .blockquote__text {
  padding-bottom: 16px;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 20px;
  line-height: 29px;
}

.blockquote_style_2 .blockquote__footer a {
  color: inherit;
}

@media (min-width: 650px) {
  .blockquote_style_2 .blockquote__text {
    font-size: 24px;
  }
}

.blockquote_style_3 {
  padding-bottom: 12px;
}

.blockquote_style_3 .blockquote__text {
  letter-spacing: 0;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 20px;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  position: relative;
}

.blockquote_style_3 .blockquote__text:before {
  content: "“";
  font-size: 150px;
  line-height: 50px;
  display: block;
  position: absolute;
  top: 18px;
  left: -8px;
}

@media (min-width: 650px) {
  .blockquote_style_3 .blockquote__text {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (min-width: 1100px) {
  .blockquote_style_3 {
    padding-bottom: 60px;
    padding-left: 420px;
    padding-right: 80px;
    position: relative;
  }

  .blockquote_style_3:before {
    content: "";
    background-image: url("quote-1.770d53c1.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 320px;
    height: 250px;
    display: block;
    position: absolute;
    top: 0;
    left: 80px;
  }

  .blockquote_cover_2:before {
    background-image: url("quote-2.337aeffa.svg");
  }

  .blockquote_cover_3:before {
    background-image: url("quote-3.307d5040.svg");
  }

  .blockquote_cover_4:before {
    background-image: url("quote-4.1ee99fe7.svg");
  }

  .blockquote_cover_5:before {
    background-image: url("quote-5.aeaf29e2.svg");
  }
}

.effect__item {
  display: inline-block;
  position: relative;
}

.effect__item_blur {
  filter: blur(2.5px);
}

.effect__item_transparency {
  --color-base: #000;
  --color-stroke-color: #fffbf4;
  -webkit-text-stroke: .5px var(--color-stroke-color);
  text-shadow: -.5px 0 var(--color-stroke-color), 0 .5px var(--color-stroke-color), .5px 0 var(--color-stroke-color), 0 -.5px var(--color-stroke-color);
  color: var(--color-base);
}

.effect__item_rotate_1 {
  top: -1px;
  transform: rotate(-4deg);
}

.effect__item_rotate_2 {
  top: -1.75px;
  transform: rotate(-6.5deg);
}

.effect__item_rotate_3 {
  top: -3.5px;
  transform: rotate(-11deg);
}

.effect__item_rotate_4 {
  top: -7.5px;
  transform: rotate(-16deg);
}

.effect__item_rotate_5 {
  top: -17px;
  left: -3px;
  transform: rotate(-21deg);
}

.flexy {
  justify-content: center;
  width: 100vw;
  display: flex;
  overflow: hidden;
}

.flexy__animation {
  width: 100%;
}

@media (min-width: 600px) {
  .flexy__animation {
    width: auto;
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .flexy__animation svg {
    width: auto !important;
    height: 350px !important;
  }
}

@media (min-width: 1100px) {
  .flexy__animation {
    margin-top: -70px;
    margin-bottom: -70px;
  }

  .flexy__animation svg {
    height: 500px !important;
  }
}

.heading {
  text-align: left;
  position: relative;
}

.heading__sup {
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  line-height: inherit;
  letter-spacing: .04em;
  text-align: left;
  vertical-align: super;
  width: 0;
  font-weight: 96;
  display: inline-block;
  position: relative;
  top: -3px;
}

.heading_style_h1 {
  letter-spacing: -.01em;
  padding-bottom: 32px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 49px;
  line-height: 52px;
}

.heading_style_h2 {
  letter-spacing: -.01em;
  padding-bottom: 4px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 10vw;
  font-weight: 200;
  line-height: 121%;
}

.heading_style_h3 {
  letter-spacing: -.01em;
  text-align: left;
  padding-bottom: 20px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
}

.heading_style_h5 {
  text-transform: uppercase;
  letter-spacing: .09em;
  padding-bottom: 6px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.heading_padded {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 375px) {
  .heading_style_h2 {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (min-width: 650px) {
  .heading_style_h1, .heading_style_h5 {
    text-align: center;
    justify-content: center;
    max-width: 950px;
    margin: 0 auto;
  }
}

@media (min-width: 1100px) {
  .heading__sup {
    top: -8px;
  }

  .heading_style_h1 {
    letter-spacing: 0;
    padding-bottom: 48px;
    font-size: 80px;
    font-weight: 800;
    line-height: 88px;
  }

  .heading_style_h2 {
    font-size: 52px;
    font-weight: 800;
    line-height: 60px;
  }

  .heading_style_h3 {
    letter-spacing: -.01em;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
  }

  .heading_style_h5 {
    letter-spacing: .06em;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}

.hero {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hero__heading {
  text-align: center;
  max-width: 80%;
  padding: 0 48px 17px;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.hero__text {
  text-align: left;
  box-sizing: border-box;
  padding: 38px 20px 40px;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

@media (min-width: 650px) {
  .hero__heading {
    letter-spacing: -.02em;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Spoof Trial Black, sans-serif;
    font-size: 56px;
    font-weight: 800;
    line-height: 58px;
  }

  .hero__text {
    text-align: center;
    max-width: 1135px;
    padding: 18px 40px 74px;
    font-size: 28px;
    line-height: 40px;
  }

  .hero__points {
    flex-direction: row-reverse;
  }
}

@media (min-width: 1100px) {
  .hero__heading {
    padding-bottom: 24px;
    font-size: min(6.8vw, 12vh, 126px);
    line-height: min(6.9vw, 12vh, 132px);
  }
}

.hero__animation {
  width: 100%;
  min-width: 100%;
  height: auto;
  max-height: 100%;
}

.hero__animation img, .hero__animation svg {
  max-width: 100%;
  max-height: 100%;
  width: 100% !important;
  height: auto !important;
}

@media (min-width: 450px) {
  .hero__animation {
    min-width: unset;
    width: auto;
    max-width: 100%;
  }
}

@media (min-width: 450px) and (max-width: 1136px) {
  .hero__animation img, .hero__animation svg {
    width: 85% !important;
    height: auto !important;
  }

  @media (orientation: landscape) {
    .hero__animation img, .hero__animation svg {
      max-height: 40vh;
    }
  }
}

@media (min-width: 1200px) {
  .hero__animation img, .hero__animation svg {
    width: auto !important;
    height: 408px !important;
  }

  @media (orientation: landscape) {
    .hero__animation img, .hero__animation svg {
      max-height: 47vh;
    }
  }
}

.menu {
  z-index: 10000;
  width: 60px;
  height: 50px;
  position: fixed;
  top: 16px;
  left: 7px;
}

.menu__control {
  cursor: pointer;
  background-color: #fffbf4;
  background-image: url("menu--burger.9b820804.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 34px;
  border: none;
  border-radius: 22px;
  width: 60px;
  height: 50px;
  display: block;
}

.menu__content {
  display: none;
}

.menu__item {
  padding-bottom: 28px;
}

.menu__link {
  color: #000;
  text-decoration: none;
}

@media (min-width: 1100px) {
  .menu {
    position: absolute;
    top: 23px;
    left: 72px;
  }

  .menu__control {
    background-size: 44px;
  }
}

.menu_open {
  background-color: #9eccc9;
  width: 100vw;
  height: 100vh;
  padding: 0;
  top: 0;
  left: 0;
}

.menu_open .menu__control {
  background-color: #0000;
  background-image: url("menu--close.857f5e73.svg");
  width: 74px;
  height: 82px;
}

.menu_open .menu__content {
  letter-spacing: 0;
  text-align: left;
  padding: 20px;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  display: block;
}

@media (min-width: 1100px) {
  .menu_open {
    width: 400px;
    position: fixed;
  }
}

.muffins-earth {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.muffins-earth__animation {
  width: 768px;
  height: 462px;
}

.muffins-earth__copyrights {
  display: none;
}

@media (min-width: 650px) {
  .muffins-earth__animation {
    width: 1097px;
    height: 660px;
  }

  .muffins-earth__copyrights {
    letter-spacing: 0;
    text-align: left;
    color: #fffbf4;
    background-color: #000;
    border: 1px solid #fffbf4;
    border-radius: 24px;
    padding: 8px 24px;
    font-family: TT Hoves Regular, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    display: block;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
  }
}

@media (min-width: 1100px) {
  .muffins-earth__animation {
    width: 1844px;
    height: 826px;
    margin-top: -172px;
    margin-bottom: -15px;
  }

  .muffins-earth__copyrights {
    bottom: 40px;
  }
}

.number {
  box-sizing: border-box;
  align-items: center;
  gap: 6px;
  display: flex;
}

.number__value {
  font-feature-settings: "ss06" on, "zero" on;
  -webkit-user-select: none;
  user-select: none;
  align-self: stretch;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 100px;
  line-height: 1;
  display: inline-block;
}

.number__value_icon_projects-scaled {
  background-image: url("projects-scaled.b9999bba.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 80px;
  padding-right: 84px;
}

.number__value_icon_number-of-projects {
  background-image: url("number-of-projects.7526f53f.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 76px;
  padding-right: 84px;
}

.number__value_icon_unicorn {
  background-image: url("unicorn.e56d93ee.svg");
  background-position: 100% 12px;
  background-repeat: no-repeat;
  background-size: 75px;
  padding-right: 95px;
}

.number__value_icon_acquired-by-unicorn {
  background-image: url("acquired-by-unicorn.427328bc.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 80px;
  padding-right: 85px;
}

.number__value_icon_faster-onboarding {
  background-image: url("faster-onboarding.6a9d4852.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 70px;
  padding-right: 80px;
}

.number__value_1-6x {
  color: #0000;
  background-image: url("1.6x.ffbf682f.svg");
  background-position-y: 9px;
  background-repeat: no-repeat;
  background-size: 158px;
  width: 160px;
}

.number__title {
  display: inline-block;
}

.number__title-row {
  display: block;
}

.number_style_2 {
  padding-bottom: 7px;
}

.number_style_2 .number__value {
  letter-spacing: .03em;
  font-size: 79px;
}

.number_style_2 .number__title {
  padding-top: 6px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 36px;
  line-height: 26px;
}

.number_style_3 .number__value {
  letter-spacing: .05em;
  font-size: 78px;
}

.number_style_3 .number__title {
  padding-top: 14px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 1100px) {
  .number__value {
    font-size: 146px;
    line-height: 120px;
  }

  .number__value_icon_projects-scaled {
    background-size: 110px;
    padding-right: 112px;
  }

  .number__value_icon_number-of-projects {
    background-size: 110px;
    padding-right: 120px;
  }

  .number__value_icon_unicorn {
    background-position-y: 0;
    background-size: 110px;
    padding-right: 140px;
  }

  .number__value_icon_acquired-by-unicorn, .number__value_icon_faster-onboarding {
    background-size: 110px;
    padding-right: 120px;
  }

  .number__value_1-6x {
    background-size: 222px;
    width: 222px;
  }

  .number_style_2 .number__value {
    line-height: 1;
  }
}

.numbers {
  justify-content: space-evenly;
  gap: 20px;
  padding: 32px 0 14px 2px;
  display: flex;
}

.numbers__text {
  padding-bottom: 0;
  padding-left: 2px;
}

@media (max-width: 350px) {
  .numbers {
    flex-direction: column;
  }
}

@media (min-width: 650px) {
  .numbers {
    justify-content: center;
    gap: 80px;
  }
}

@media (min-width: 1100px) {
  .numbers {
    gap: 110px;
    padding-top: 24px;
  }

  .numbers__number {
    padding-bottom: 10px;
  }

  .numbers__text {
    text-align: left;
    margin-top: -8px;
  }
}

.outstander {
  border-radius: 32px;
  margin: 0 10px;
  overflow: hidden;
}

.outstander__row {
  --dot-image: url("data:image/svg+xml;utf8,<svg width=\"10\" height=\"10\" viewBox=\"0 0 10 10\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"%23FFFBF4\"/></svg> ");
  --dot-size: 16px;
  --dot-vertical-margin: 38px;
  --dot-horizontal-margin: 18px;
  background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image);
  background-position: top calc((var(--dot-size) * -1) / 2) left calc((var(--dot-size) * -1) / 2), top calc((var(--dot-size) * -1) / 2) right calc((var(--dot-size) * -1) / 2), bottom calc((var(--dot-size) * -1) / 2) right calc((var(--dot-size) * -1) / 2), bottom calc((var(--dot-size) * -1) / 2) left calc((var(--dot-size) * -1) / 2), top var(--dot-vertical-margin) right var(--dot-horizontal-margin), bottom var(--dot-vertical-margin) right var(--dot-horizontal-margin);
  background-size: var(--dot-size) var(--dot-size);
  color: #fffbf4;
  box-sizing: border-box;
  background-color: #000;
  background-repeat: no-repeat;
  border-bottom: 1px dashed #fffbf4;
  border-image: repeating-linear-gradient(to right, #0000 0 8px, #fffbf4 0 16px) 25;
  padding: 38px 18px;
  position: relative;
}

.outstander__row_type_header {
  background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), none, var(--dot-image);
}

.outstander__row_type_footer {
  background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), none;
  border-bottom: none;
}

.outstander__heading {
  padding-bottom: 25px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 48px;
}

.outstander__highlight {
  padding-right: 25px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

@media (min-width: 1184px) {
  .outstander {
    border-radius: 76px;
    max-width: 1144px;
    margin: 0 auto;
  }
}

@media (min-width: 1100px) {
  .outstander__row {
    --dot-size: 21px;
    --dot-vertical-margin: 66px;
    --dot-horizontal-margin: 64px;
    background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image);
    padding: 52px 104px 52px 64px;
  }

  .outstander__row_type_header {
    grid-gap: 20px;
    background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), none, var(--dot-image);
    grid-template-columns: 456px 1fr;
    align-items: center;
    padding-bottom: 25px;
    display: grid;
  }

  .outstander__row_type_footer {
    background-image: var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), var(--dot-image), none;
    padding-top: 32px;
    padding-bottom: 38px;
  }

  .outstander__heading {
    letter-spacing: -.02em;
    font-size: 80px;
    line-height: 88px;
  }

  .outstander__highlight {
    font-size: 24px;
  }
}

.page {
  color: #000;
  background: #fffbf4;
  font-family: sans-serif;
}

.page__menu {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.page__header {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
}

.page__heading {
  letter-spacing: -.02em;
  text-align: center;
  padding: 13px 20px 10px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 42px;
  line-height: 53px;
}

.page_lock {
  height: 100%;
  overflow: hidden;
}

@media (min-width: 650px) {
  .page__heading {
    letter-spacing: 0;
    line-height: 48px;
  }
}

@media (min-width: 1100px) {
  .page__heading {
    padding-top: 24px;
    padding-bottom: 20px;
  }

  .page_lock {
    height: auto;
    overflow: visible;
  }
}

.partner {
  --toggler-size: 40px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 15px;
  padding: 21px 20px 0;
}

.partner__heading {
  padding-right: calc(var(--toggler-size)  + 8px);
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  position: relative;
}

.partner__summary-control {
  cursor: pointer;
  width: var(--toggler-size);
  height: var(--toggler-size);
  background-image: url("partner--open.f93f873a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.partner__summary-control_type_mobile {
  margin-top: -21px;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.partner__summary-control_type_desktop, .partner__content {
  display: none;
}

.partner__tags {
  gap: 12px;
  display: none;
}

.partner__tag {
  letter-spacing: 0;
  text-align: center;
  box-sizing: border-box;
  background: none;
  border: 2px solid #f0e3cb;
  border-radius: 70px;
  margin: 0;
  padding: 10px 30px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
}

.partner__tag_style_link {
  border-style: dashed;
}

.partner__tag_style_link:after {
  content: "";
  background-image: url("partner--link.a8552524.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.partner__tag_style_link a {
  color: #000;
  text-decoration: none;
}

.partner__tag_style_1 {
  color: #fffbf4;
  background: #000;
  border-color: #000;
}

.partner__tag_style_2 {
  background: #f6ead4;
  border-color: #0000;
  border-radius: 0;
}

.partner__tag_style_3 {
  background: #f6ead4;
  border-color: #0000;
  border-radius: 12px;
}

.partner a {
  color: inherit;
}

@media (max-width: 350px) {
  .partner {
    --toggler-size: 30px;
  }

  .partner__summary-control_type_mobile {
    margin-top: 0;
    top: 5px;
  }
}

@media (min-width: 1240px) {
  .partner {
    --toggler-size: 44px;
    max-width: 1348px;
    margin: 0 auto 16px;
    padding: 16px 34px;
  }

  .partner__summary {
    grid-template-columns: 540px 1fr 44px;
    display: grid;
  }

  .partner__summary-control_type_desktop {
    margin-top: 8px;
    display: block;
  }

  .partner__summary-control_type_mobile {
    display: none;
  }

  .partner__tags {
    flex-wrap: wrap;
    align-self: baseline;
    padding-top: 8px;
    display: flex;
  }

  .partner__tag_type_content {
    display: none;
  }

  .partner:hover {
    background-color: #fcf4e5;
    border-radius: 40px;
  }
}

.partner_open {
  background-color: #fcf4e5;
  border-radius: 32px;
  margin-bottom: 31px;
  padding-bottom: 32px;
}

.partner_open .partner__content {
  display: block;
}

.partner_open .partner__summary-control {
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  background-image: url("partner--close.aa636d08.svg");
}

.partner_open .partner__tags {
  flex-wrap: wrap;
  margin: 32px 0;
  display: flex;
}

.partner_open .partner__blockquote {
  margin-left: -20px;
  margin-right: -20px;
}

@media (min-width: 650px) {
  .partner_open .partner__blockquote {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1240px) {
  .partner_open .partner__about {
    padding-left: 540px;
  }

  .partner_open .partner__tags {
    margin: 0 0 44px;
  }

  .partner_open .partner__tag_type_content {
    display: inline-block;
  }
}

.path {
  --color-default: #efe8e1;
  --color-activated: #000;
  --color-current: var(--color-default);
  --counter-size: 38px;
  --border-width: 2px;
  --line-left: 25px;
  --line-height: 0;
  box-sizing: border-box;
  counter-reset: my-awesome-counter;
  padding: 0 20px;
  position: relative;
}

.path:before, .path:after {
  content: "";
  top: 0;
  left: var(--line-left);
  width: 2px;
  max-height: 100%;
  display: block;
  position: absolute;
}

.path:before {
  background: var(--color-default);
  z-index: 1001;
  height: 100%;
}

.path:after {
  height: var(--line-height);
  z-index: 1002;
  background: var(--color-activated);
}

.path__item {
  --activated-height: 0;
  counter-increment: my-awesome-counter;
  box-sizing: border-box;
  margin-left: 5px;
  padding-bottom: 49px;
  padding-left: 30px;
  position: relative;
}

.path__item:last-of-type {
  padding-bottom: 0;
}

.path__item:before {
  box-sizing: border-box;
  content: counter(my-awesome-counter);
  z-index: 1010;
  top: 0;
  left: calc((var(--counter-size) / -2));
  font-feature-settings: "ss06" on, "zero" on;
  letter-spacing: 0;
  width: var(--counter-size);
  height: var(--counter-size);
  border: var(--border-width) solid var(--color-current);
  color: var(--color-current);
  background: #fffbf4;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 20px;
  display: flex;
  position: absolute;
}

.path__item:last-of-type:after {
  content: "";
  z-index: 1003;
  background: #fffbf4;
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: -2px;
}

.path__animation {
  width: 300px;
  margin-bottom: 20px;
  position: relative;
}

.path__animation svg {
  width: 100%;
}

.path__item_activated {
  --color-current: var(--color-activated);
}

.path__item_activated:before {
  transition: color .2s ease-in-out, border-color .2s ease-in-out;
}

@media (min-width: 650px) {
  .path {
    --line-left: calc(340px + 18px);
  }

  .path__item {
    margin-left: 340px;
    padding-bottom: 124px;
    position: relative;
  }

  .path__animation {
    position: absolute;
    top: -32px;
    left: -340px;
  }
}

@media (min-width: 1100px) {
  .path {
    --counter-size: 88px;
    --line-left: calc(50% - 1px);
    margin-top: 20px;
  }

  .path__item {
    max-width: 570px;
    margin-left: 50%;
    padding-left: 120px;
  }

  .path__item:last-of-type {
    padding-bottom: 60px;
  }

  .path__item:before {
    font-size: 24px;
    line-height: 24px;
  }

  .path__animation {
    width: 400px;
    left: -520px;
  }
}

.section {
  margin: 80px auto 0;
}

.section_strict {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.section_padded {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1100px) {
  .section {
    margin-top: 148px;
  }
}

.slider {
  box-sizing: border-box;
  width: 100%;
  max-width: 1144px;
  margin: auto;
  padding: 6px 0 0;
  position: relative;
  overflow: hidden;
}

.slider__slides {
  transition: transform .5s ease-in-out;
  display: flex;
}

.slider__slide {
  box-sizing: border-box;
  min-width: 100%;
  padding: 0 20px;
}

.slider__controls {
  display: none;
}

.slider__control {
  cursor: pointer;
  background-color: #0000;
  background-image: url("slider--control.1e64b9f6.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 40px;
  height: 60px;
}

.slider__control_to_prev {
  transform: scaleX(-1);
}

.slider__dots {
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  height: 12px;
  display: flex;
}

.slider__dot {
  cursor: pointer;
  background-color: #efe1c5;
  border: none;
  border-radius: 50%;
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  display: block;
}

.slider__dot_active {
  background-color: #000;
}

@media (min-width: 650px) {
  .slider__slide {
    text-align: center;
    padding-left: 80px;
    padding-right: 80px;
  }

  .slider_style_beige {
    border-radius: 64px;
  }

  .slider_style_beige .slider__slide {
    padding-left: 60px;
    padding-right: 60px;
  }

  .slider_style_beige .slider__dots {
    justify-content: center;
  }

  .slider__slide {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1100px) {
  .slider__dots {
    padding-top: 12px;
  }

  .slider__controls {
    justify-content: space-between;
    width: 100%;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.stats {
  grid-gap: 29px 10px;
  grid-template-columns: 1fr;
  display: grid;
}

.stats__item_globe {
  background-image: url("stats--globe.96379328.svg");
  background-position: 0 100%;
  background-repeat: no-repeat;
  padding-bottom: 52px;
}

.stats__item_follow:after {
  content: "";
  background-image: url("stats--follow.19359265.svg");
  background-position: right 12px;
  background-repeat: no-repeat;
  width: 94px;
  height: 62px;
  display: block;
}

.stats__text {
  padding-bottom: 11px;
}

.stats_compact {
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10px;
}

.stats_compact .stats__item:first-of-type {
  grid-column: 1 / span 2;
}

@media (max-width: 370px) {
  .stats_compact {
    grid-template-columns: 1fr;
  }

  .stats_compact .stats__item:first-of-type {
    grid-column: 1;
  }

  .stats__item_follow {
    flex-wrap: wrap;
  }

  .stats__item_follow:after {
    background-position: center;
    background-size: 90%;
    width: 65px;
    height: 48px;
  }
}

@media (min-width: 650px) {
  .stats {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  .stats_compact {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 0;
  }

  .stats_compact .stats__item:first-of-type {
    grid-column: 1 / span 3;
  }
}

@media (min-width: 1000px) {
  .stats {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 0;
  }

  .stats_compact {
    grid-template-rows: auto;
    grid-template-columns: 324px 1fr 1fr 1fr;
  }

  .stats_compact .stats__item:first-of-type {
    grid-column: 1;
  }
}

.text_style_1 {
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.text_style_1 b {
  font-family: TT Hoves Bold, sans-serif;
}

.text_style_2 {
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.text_style_2 b {
  font-family: TT Hoves Bold, sans-serif;
}

.text_style_3 {
  font-family: TT Hoves Regular, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.text_style_3 b {
  font-family: TT Hoves Bold, sans-serif;
}

.text_style_4 {
  font-family: TT Hoves Regular, sans-serif;
  font-size: 28px;
  line-height: 32px;
}

.text_style_4 b {
  font-family: Spoof Trial Black, sans-serif;
}

@media (min-width: 650px) {
  .text_style_2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.things {
  width: 100%;
  max-width: 1129px;
  margin: 0 auto;
  font-size: 0;
}

.things__category {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  z-index: 2;
  background-color: #0000;
  border: 2px solid #000;
  border-radius: 70px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 18px 24px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.things__category_open {
  color: #fffbf4;
  background-color: #000;
  margin: 0;
}

.things__item {
  color: #000;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
  background-color: #fcf4e5;
  border: 2px solid #fcf4e5;
  border-radius: 70px;
  padding: 18px 24px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 16px;
  line-height: 20px;
  display: none;
}

.things__item_open {
  display: inline-block;
}

.things__item_character_muffin {
  --muffin-width: 119px;
  --muffin-height: 60px;
  color: #0000;
  width: var(--muffin-width);
  height: var(--muffin-height);
  background-color: #0000;
  background-image: url("things--muffin.8ff09ded.svg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: var(--muffin-width) var(--muffin-height);
  vertical-align: top;
  border: none;
  border-radius: 0;
  padding: 0;
  font-size: 0 !important;
  line-height: 0 !important;
}

@media (min-width: 650px) {
  .things {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

  .things__category, .things__item {
    padding: 30px 48px;
    font-size: 20px;
  }

  .things__item_character_muffin {
    --muffin-width: 166px;
    --muffin-height: 84px;
    background-image: url("things--muffin-desktop.acc8549d.svg");
  }
}

.why-us-list__term {
  letter-spacing: .01em;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 40px;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 36px;
  line-height: 47px;
}

.why-us-list__description {
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 24px;
  font-family: TT Hoves Regular, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
}

.why-us-list__description:last-of-type {
  padding-bottom: 0;
}

@media (min-width: 1000px) {
  .why-us-list {
    grid-gap: 20px;
    grid-template-columns: 456px 1fr;
    display: grid;
  }

  .why-us-list__description {
    padding-bottom: 76px;
  }
}

.word__slider {
  background: #fcf4e5;
  border-radius: 32px;
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 24px;
}

.word__slider .slider__slide {
  padding-left: 14px;
  padding-right: 14px;
}

.word__slider .slider__dots {
  justify-content: start;
  padding-left: 20px;
}

.word__slider .slider__control {
  background-image: url("word--control.62a9c845.svg");
}

@media (min-width: 1100px) {
  .word {
    background: #fcf4e5;
    border-radius: 64px;
    max-width: 1171px;
    padding-top: 64px;
    padding-bottom: 24px;
  }

  .word__heading {
    padding-bottom: 16px;
  }

  .word__slider .slider__controls {
    top: 116px;
  }

  .word__slider .slider__dots {
    justify-content: center;
    padding-top: 0;
    padding-left: 0;
  }
}

@font-face {
  font-family: Spoof Trial Black;
  src: url("SpoofTrial-Black.cfe02c1a.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: Spoof Trial Medium;
  src: url("SpoofTrial-Medium.8650f900.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: TT Hoves Bold;
  src: url("TT Hoves Bold.4e3a0d16.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: TT Hoves Regular;
  src: url("TT Hoves Regular.8b41ef9c.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

:root {
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

html, body {
  box-sizing: border-box;
  background: #fffbf4;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

html:after, body:after {
  content: "";
  background: #fffbf4;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
}

html:after {
  top: -100vh;
}

body:after {
  bottom: -100vh;
}

img {
  max-width: 100%;
}

.visually-hidden {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.muffins {
  font-family: Spoof Trial Black, sans-serif;
  display: block;
}

@media (min-width: 350px) and (max-width: 449px) {
  .muffins {
    letter-spacing: 1px;
    text-align: center;
    font-size: 20vw;
    font-weight: 800;
    line-height: 71px;
  }
}

@media (min-width: 450px) {
  .muffins {
    display: inline;
  }
}

.logos {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding: 0 10px;
  display: flex;
}

.logos__item {
  height: 60px;
  padding-bottom: 4px;
}

@media (min-width: 1100px) {
  .logos {
    gap: 72px;
  }
}

.cite {
  text-align: left;
  gap: 12px;
  display: flex;
}

.cite__image {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-top: 12px;
}

@media (min-width: 400px) {
  .cite__image {
    margin-top: 0;
  }
}

.email {
  letter-spacing: 0;
  text-align: left;
  color: #000;
  width: -moz-fit-content;
  width: fit-content;
  font-family: Spoof Trial Medium, sans-serif;
  font-size: 32px;
  font-weight: 96;
  line-height: 1;
  text-decoration: none;
  position: relative;
}

.email:after {
  content: "";
  z-index: -1;
  background: #f9cb67;
  width: 100%;
  height: 8px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 1100px) {
  .email {
    font-size: 40px;
  }

  .email:after {
    height: 14px;
    bottom: 1px;
  }
}

.contacts {
  flex-direction: column;
  gap: 32px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  display: flex;
}

.contacts__item {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

@media (min-width: 650px) {
  .contacts {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 20px;
    padding-top: 4px;
  }

  .contacts__item {
    text-align: center;
    gap: 16px;
  }
}

.nutella {
  text-align: left;
  font-family: Spoof Trial Black, sans-serif;
  font-size: 36px;
  font-weight: 200;
  line-height: 48px;
}

.nutella__title {
  padding-bottom: 32px;
}

.nutella__highlight {
  color: #f97362;
}

@media (min-width: 1100px) {
  .nutella {
    grid-gap: 20px;
    grid-template-columns: 300px 1fr;
    display: grid;
  }
}

/*# sourceMappingURL=index.e3fbd431.css.map */
