.slider {
  position: relative;
  width: 100%;
  max-width: 1144px;
  margin: auto;
  padding: 6px 0 0;
  overflow: hidden;
  box-sizing: border-box;
}
.slider__slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.slider__slide {
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}
.slider__controls {
  display: none;
}
.slider__control {
  border: none;
  width: 40px;
  height: 60px;
  background-color: transparent;
  background-image: url("../images/slider--control.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.slider__control_to_prev {
  transform: scaleX(-1);
}
.slider__dots {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  height: 12px;
}
.slider__dot {
  flex: 0 0 12px;
  border: none;
  height: 12px;
  width: 12px;
  background-color: #EFE1C5;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}
.slider__dot_active {
  background-color: #000;
}

@media (min-width: 650px) {
  .slider__slide {
    padding-left: 80px;
    padding-right: 80px;
    text-align: center;
  }
  .slider_style_beige {
    border-radius: 64px;
  }
  .slider_style_beige .slider__slide {
    padding-left: 60px;
    padding-right: 60px;
  }
  .slider_style_beige .slider__dots {
    justify-content: center;
  }
}

@media (min-width: 650px) {
  .slider__slide {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1100px) {
  .slider__dots {
    padding-top: 12px;
  }
  .slider__controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
}
