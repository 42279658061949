.outstander {
  margin: 0 10px;
  border-radius: 32px;
  overflow: hidden;
}

.outstander__row {
  --dot-image: url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5" cy="5" r="5" fill="%23FFFBF4"/></svg> ');
  --dot-size: 16px;
  --dot-vertical-margin: 38px;
  --dot-horizontal-margin: 18px;
  background-image:
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image);
  background-position:
    top calc((var(--dot-size) * -1) / 2) left calc((var(--dot-size) * -1) / 2),
    top calc((var(--dot-size) * -1) / 2) right calc((var(--dot-size) * -1) / 2),
    bottom calc((var(--dot-size) * -1) / 2) right calc((var(--dot-size) * -1) / 2),
    bottom calc((var(--dot-size) * -1) / 2) left calc((var(--dot-size) * -1) / 2),
    top var(--dot-vertical-margin) right var(--dot-horizontal-margin),
    bottom var(--dot-vertical-margin) right var(--dot-horizontal-margin);
  background-size: var(--dot-size) var(--dot-size);
  background-repeat: no-repeat;
  background-color: #000;
  color: #FFFBF4;
  border-bottom: 1px dashed #FFFBF4;
  border-image: repeating-linear-gradient(to right, transparent 0px, transparent 8px, #FFFBF4 0px, #FFFBF4 16px) 25;
  padding: 38px 18px 38px 18px;
  position: relative;
  box-sizing: border-box;
}

.outstander__row_type_header {
  background-image:
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    none,
    var(--dot-image);
}
.outstander__row_type_footer {
  border-bottom: none;
  background-image:
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    var(--dot-image),
    none;
}
.outstander__heading {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 48px;
  padding-bottom: 25px;
}
.outstander__highlight {
  font-family: "TT Hoves Regular", sans-serif;
  padding-right: 25px;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

@media (min-width: 1184px) {
  .outstander {
    border-radius: 76px;
    max-width: 1144px;
    margin: 0 auto;
  }
}

@media (min-width: 1100px) {
  .outstander__row {
    --dot-size: 21px;
    --dot-vertical-margin: 66px;
    --dot-horizontal-margin: 64px;
    background-image:
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image);
    padding: 52px 104px 52px 64px;
  }
  .outstander__row_type_header {
    display: grid;
    grid-template-columns: 456px 1fr;
    grid-gap: 20px;
    align-items: center;
    padding-bottom: 25px;
    background-image:
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      none,
      var(--dot-image);
  }
  .outstander__row_type_footer {
    padding-top: 32px;
    padding-bottom: 38px;
    background-image:
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      var(--dot-image),
      none;
  }
  .outstander__heading {
    font-size: 80px;
    line-height: 88px;
    letter-spacing: -0.02em;
  }
  .outstander__highlight {
    font-size: 24px;
  }
}
