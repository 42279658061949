.stats {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 29px 10px;
}
.stats__item {}
.stats__item_globe {
  padding-bottom: 52px;
  background-image: url("../images/stats--globe.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
}
.stats__item_follow::after {
  content: "";
  display: block;
  width: 94px;
  height: 62px;
  background-image: url("../images/stats--follow.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: 12px;
}

.stats__text {
  padding-bottom: 11px;
}

.stats_compact {
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10px;
}
.stats_compact .stats__item:first-of-type {
  grid-column: 1 / span 2;
}

@media (max-width: 370px) {
  .stats_compact {
    grid-template-columns: 1fr;
  }
  .stats_compact .stats__item:first-of-type {
    grid-column: 1;
  }
  .stats__item_follow {
    flex-wrap: wrap;
  }
  .stats__item_follow::after {
    width: 65px;
    height: 48px;
    background-position: center;
    background-size: 90%;
  }
}

@media (min-width: 650px) {
  .stats {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
  .stats__item {
  }
  .stats_compact {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 0;
  }
  .stats_compact .stats__item:first-of-type {
    grid-column: 1 / span 3;
  }
}

@media (min-width: 1000px) {
  .stats {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    padding-top: 0;
  }
  .stats_compact {
    grid-template-rows: auto;
    grid-template-columns: 324px 1fr 1fr 1fr;
  }
  .stats_compact .stats__item:first-of-type {
    grid-column: 1;
  }
}
