.page {
  background: #FFFBF4;
  font-family: sans-serif;
  color: #000;
}
.page__menu {
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}
.page__header {
  text-align: center;
  max-width: 1440px;
  margin: 0 auto;
}
.page__heading {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 42px;
  line-height: 53px;
  letter-spacing: -0.02em;
  text-align: center;
  padding: 13px 20px 10px;
}
.page__main {}
.page__footer {}

.page_lock {
  height: 100%;
  overflow: hidden;
}

@media (min-width: 650px) {
  .page__heading {
    line-height: 48px;
    letter-spacing: 0;
  }
}

@media (min-width: 1100px) {
  .page__heading {
    padding-top: 24px;
    padding-bottom: 20px;
  }

  .page_lock {
    height: auto;
    overflow: visible;
  }
}
