.heading {
  text-align: left;
  position: relative;
}
.heading__sup {
  font-family: "TT Hoves Regular", sans-serif;
  font-size: 16px;
  line-height: inherit;
  letter-spacing: 0.04em;
  text-align: left;
  font-weight: 96;
  vertical-align: super;
  display: inline-block;
  position: relative;
  top: -3px;
  width: 0;
}

.heading_style_h1 {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 49px;
  line-height: 52px;
  letter-spacing: -0.01em;
  padding-bottom: 32px;
}

.heading_style_h2 {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 10vw;
  font-weight: 200;
  line-height: 121%;
  letter-spacing: -0.01em;
  padding-bottom: 4px;
}

.heading_style_h3 {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  padding-bottom: 20px;
}

.heading_style_h5 {
  font-family: "TT Hoves Regular", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.09em;
  padding-bottom: 6px;
}

.heading_padded {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 375px) {
  .heading_style_h2 {
    font-size: 40px;
    line-height: 48px;
  }
}

@media (min-width: 650px) {
  .heading_style_h1,
  .heading_style_h5 {
    text-align: center;
    justify-content: center;
    max-width: 950px;
    margin: 0 auto;
  }
}

@media (min-width: 1100px) {
  .heading__sup {
    top: -8px;
  }
  .heading_style_h1 {
    font-size: 80px;
    font-weight: 800;
    line-height: 88px;
    letter-spacing: 0;
    padding-bottom: 48px;
  }
  .heading_style_h2 {
    font-size: 52px;
    font-weight: 800;
    line-height: 60px;
  }
  .heading_style_h3 {
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    letter-spacing: -0.01em;
  }
  .heading_style_h5 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0.06em;
  }
}
