.word {}
.word__heading {}
.word__slider {
  background: #FCF4E5;
  border-radius: 32px;
  padding-top: 50px;
  padding-bottom: 24px;
  max-width: 100%;
}

.word__slider .slider__slide {
  padding-left: 14px;
  padding-right: 14px;
}
.word__slider .slider__dots {
  justify-content: start;
  padding-left: 20px;
}
.word__slider .slider__control {
  background-image: url("../images/word--control.svg");
}

@media (min-width: 1100px) {
  .word {
    background: #FCF4E5;
    border-radius: 64px;
    padding-top: 64px;
    padding-bottom: 24px;
    max-width: 1171px;
  }
  .word__heading {
    padding-bottom: 16px;
  }
  .word__slider .slider__controls {
    top: 116px;
  }
  .word__slider .slider__dots {
    justify-content: center;
    padding-left: 0;
    padding-top: 0;
  }
}
