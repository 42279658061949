.effect {
}
.effect__item {
  position: relative;
  display: inline-block;
}

/* .effect_activated {
  transition: all .5s ease;
} */
/* .effect_activated .effect__item {
  transition: all .5s ease;
} */

/* .effect_activated */ .effect__item_blur {
  filter: blur(2.5px);
}

/* .effect_activated */ .effect__item_transparency {
  --color-base: #000;
  --color-stroke-color: #fffbf4;
  -webkit-text-stroke: 0.5px var(--color-stroke-color);
  text-shadow: -0.5px 0  var(--color-stroke-color), 0 0.5px  var(--color-stroke-color), 0.5px 0  var(--color-stroke-color), 0 -0.5px  var(--color-stroke-color);
  color: var(--color-base);
}

/* .effect_activated */ .effect__item_rotate_1 {
  transform: rotate(-4deg);
  top: -1px;
}
/* .effect_activated */ .effect__item_rotate_2 {
  transform: rotate(-6.5deg);
  top: -1.75px;
}
/* .effect_activated */ .effect__item_rotate_3 {
  transform: rotate(-11deg);
  top: -3.5px;
}
/* .effect_activated */ .effect__item_rotate_4 {
  transform: rotate(-16deg);
  top: -7.5px;
}
/* .effect_activated */ .effect__item_rotate_5 {
  transform: rotate(-21deg);
  top: -17px;
  left: -3px;
}
