.things {
  width: 100%;
  max-width: 1129px;
  margin: 0 auto;
  font-size: 0;
}
.things__category {
  cursor: pointer;
  font-family: "TT Hoves Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 24px;
  border-radius: 70px;
  box-sizing: border-box;
  text-align: center;
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-right: -1px;
  z-index: 2;
}
.things__category_open {
  background-color: #000;
  color: #FFFBF4;
  margin: 0;
}
.things__item {
  display: none;
  font-family: "TT Hoves Regular", sans-serif;
  font-size: 16px;
  line-height: 20px;
  padding: 18px 24px;
  border-radius: 70px;
  background-color: #FCF4E5;
  color: #000;
  border: 2px solid #FCF4E5;
  box-sizing: border-box;
  text-align: center;
  z-index: 1;
}
.things__item_open {
  display: inline-block;
}
.things__item_character_muffin {
  --muffin-width: 119px;
  --muffin-height: 60px;
  color: transparent;
  width: var(--muffin-width);
  height: var(--muffin-height);
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  background-image: url("../images/things--muffin.svg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: var(--muffin-width) var(--muffin-height);
  font-size: 0 !important;
  line-height: 0 !important;
  vertical-align: top;
}

@media (min-width: 650px) {
  .things {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .things__category,
  .things__item {
    font-size: 20px;
    padding: 30px 48px;
  }
  .things__item_character_muffin {
    --muffin-width: 166px;
    --muffin-height: 84px;
    background-image: url("../images/things--muffin-desktop.svg");
  }
}

