.number {
  display: flex;
  gap: 6px;
  box-sizing: border-box;
  align-items: center;
}
.number__value {
  display: inline-block;
  font-family: "Spoof Trial Black", sans-serif;
  font-feature-settings: 'ss06' on, 'zero' on;
  font-size: 100px;
  line-height: 1;
  align-self: stretch;

  user-select: none;
}
.number__value_icon_projects-scaled {
  background-image: url("../images/numbers/projects-scaled.svg");
  background-repeat: no-repeat;
  padding-right: 84px;
  background-size: 80px;
  background-position: right;
}
.number__value_icon_number-of-projects {
  background-image: url("../images/numbers/number-of-projects.svg");
  background-repeat: no-repeat;
  padding-right: 84px;
  background-size: 76px;
  background-position: right;
}
.number__value_icon_unicorn {
  background-image: url("../images/numbers/unicorn.svg");
  background-position: 100% 12px;
  background-repeat: no-repeat;
  background-size: 75px;
  padding-right: 95px;
}
.number__value_icon_acquired-by-unicorn {
  background-image: url("../images/numbers/acquired-by-unicorn.svg");
  background-repeat: no-repeat;
  padding-right: 85px;
  background-size: 80px;
  background-position: right;
}
.number__value_icon_faster-onboarding {
  background-image: url("../images/numbers/faster-onboarding.svg");
  background-repeat: no-repeat;
  padding-right: 80px;
  background-size: 70px;
  background-position: right;
}
.number__value_1-6x {
  color: transparent;
  background-image: url("../images/numbers/1.6x.svg");
  background-repeat: no-repeat;
  background-position-y: 9px;
  background-size: 158px;
  width: 160px;
}
.number__title {
  display: inline-block;
}
.number__title-row {
  display: block;
}

.number_style_2 {
  padding-bottom: 7px;
}
.number_style_2 .number__value {
  font-size: 79px;
  letter-spacing: 0.03em;
}
.number_style_2 .number__title {
  font-family: "Spoof Trial Black", sans-serif;
  font-size: 36px;
  padding-top: 6px;
  line-height: 26px;
}

.number_style_3 {}
.number_style_3 .number__value {
  font-size: 78px;
  letter-spacing: 0.05em;
}
.number_style_3 .number__title {
  font-family: "TT Hoves Regular", sans-serif;
  font-size: 20px;
  line-height: 28px;
  padding-top: 14px;
}

@media (min-width: 1100px) {
  .number__value {
    font-size: 146px;
    line-height: 120px;
  }
  .number__value_icon_projects-scaled {
    padding-right: 112px;
    background-size: 110px;
  }
  .number__value_icon_number-of-projects {
    padding-right: 120px;
    background-size: 110px;
  }
  .number__value_icon_unicorn {
    padding-right: 140px;
    background-size: 110px;
    background-position-y: 0;
  }
  .number__value_icon_acquired-by-unicorn {
    padding-right: 120px;
    background-size: 110px;
  }
  .number__value_icon_faster-onboarding {
    padding-right: 120px;
    background-size: 110px;
  }
  .number__value_1-6x {
    background-size: 222px;
    width: 222px;
  }

  .number_style_2 .number__value {
    line-height: 1;
  }
}
