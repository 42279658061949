.flexy {
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.flexy__animation {
  width: 100%;
}

@media (min-width: 600px) {
  .flexy__animation {
    width: auto;
    margin-top: -50px;
    margin-bottom: -50px;
  }

  .flexy__animation svg {
    width: auto !important;
    height: 350px !important;
  }
}

@media (min-width: 1100px) {
  .flexy__animation {
    margin-top: -70px;
    margin-bottom: -70px;
  }
  .flexy__animation svg {
    height: 500px !important;
  }
}
