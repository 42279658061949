.numbers {
  display: flex;
  padding: 32px 0 14px 2px;
  justify-content: space-evenly;
  gap: 20px;
}
.numbers__item {}
.numbers__number {}
.numbers__text {
  padding-bottom: 0;
  padding-left: 2px;
}

@media (max-width: 350px) {
  .numbers {
    flex-direction: column;
  }
}
@media (min-width: 650px) {
  .numbers {
    justify-content: center;
    gap: 80px;
  }
}

@media (min-width: 1100px) {
  .numbers {
    padding-top: 24px;
    gap: 110px;
  }
  .numbers__number {
    padding-bottom: 10px;
  }
  .numbers__text {
    margin-top: -8px;
    text-align: left;
  }
}
