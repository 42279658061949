.section {
  margin: 80px auto 0;
}
.section_strict {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
.section_padded {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1100px) {
  .section {
    margin-top: 148px;
  }
}
